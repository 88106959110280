.message_box{
    grid-column: span 3;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -12px;
    border-radius: 0 0 3px 3px;
    padding: 0 0 10px 0;
}

.message{
    color: #343A40;
    font-size: 20px;
    text-align: center;
}

@media screen and (max-width:1150px){
    .message_box{
        grid-column: span 2;
    }
}

@media screen and (max-width:500px){
    .message_box{
        grid-column: span 1;
    }
}

.message_starting{
    padding: 0 10px 0 10px;
    color: #343A40;
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    font-family: 'Montserrat',sans-serif;
}

.wrapper {
    width: 90%;
    max-width: 1536px;
    margin-inline: auto;
    position: relative;
    height: 100px;
    overflow: hidden;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0)
    );
  }
  
  @keyframes scrollLeft {
    to {
      left: -200px;
    }
  }
  
  .item {
    width: 100px;
    height: 80px;
    background-color: transparent;
    border-radius: 6px;
    position: absolute;
    left: max(calc(100px * 8), 100%);
    object-fit: contain;
    animation-name: scrollLeft;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  .item1 {
    animation-delay: calc(30s / 8 * (8 - 1) * -1);
  }
  
  .item2 {
    animation-delay: calc(30s / 8 * (8 - 2) * -1);
  }
  
  .item3 {
    animation-delay: calc(30s / 8 * (8 - 3) * -1);
  }
  
  .item4 {
    animation-delay: calc(30s / 8 * (8 - 4) * -1);
  }
  
  .item5 {
    animation-delay: calc(30s / 8 * (8 - 5) * -1);
  }
  
  .item6 {
    animation-delay: calc(30s / 8 * (8 - 6) * -1);
  }
  
  .item7 {
    animation-delay: calc(30s / 8 * (8 - 7) * -1);
  }
  
  .item8 {
    animation-delay: calc(30s / 8 * (8 - 8) * -1);
  }